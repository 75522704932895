import React from "react";
import loadable from "@loadable/component";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import {
  autoOverviewHero,
  autoOverviewWhiteGlove,
  autoOverviewMultiSection,
  autoOverviewCTA,
  autoOverviewVideo,
  autoOverviewTestimonials,
  autoOverviewSubverticals,
  autoOverviewPartIntegration,
  autpOverviewTrusted,
  autoBusinessTypes,
} from "../data/auto-overview-data";
import { NorthEastAutoSlides, heroSuccess } from "../data/success-stories-data";
import Hero from "../components/Hero/hero";

import ogImage from "../images/global_assets/og-image.png";

const WhiteGlove = loadable(() =>
  import("../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../components/TestimonialReviews/TestmonialReviews")
);
const IntegrationsSection = loadable(() =>
  import("../components/Integrations/IntegrationsSection")
);
const TrustedNumbers = loadable(() =>
  import("../components/TrustedNumbers/TrustedNumbers")
);
const BusinessTypes = loadable(() =>
  import("../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "bto-white-glove.png";

const AutoOverview = () => {
  return (
    <Layout>
      <SEO
        title="Automotive | Point-of-Sale &amp; Digital Tools"
        description="Keeping up with trends and providing your customers with 
          the service they expect at an affordable price is essential to remain 
          competitive in the automotive industry. SpotOn’s custom-tailored point-of-sale 
          and digital tools will help you boost business efficiency and improve customer retention."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={autoOverviewHero} circleBg={false} />
      <TrustedNumbers numbersArray={autpOverviewTrusted} />

      <WhiteGlove
        sectionData={autoOverviewWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <BusinessTypes sectionData={autoBusinessTypes} />
      {/* <Subverticals sectionData={autoOverviewSubverticals} /> */}
      <LargeFeatures sectionData={autoOverviewMultiSection} />
      <VideoSection sectionData={autoOverviewVideo} />
      <IntegrationsSection
        sectionData={autoOverviewPartIntegration}
        integrationOverride
      />
      <SuccessStories sectionData={heroSuccess} slides={NorthEastAutoSlides} />
      <LargeCta sectionData={autoOverviewCTA} />
      <TestmonialReviews sectionData={autoOverviewTestimonials} />
      {/* <PartnerLogos /> */}
      {/* <ByTheNumbers sectionData={byTheNumbers} />
      <Faqs sectionData={autoOverviewFaqs} /> */}
      {/*      <Articles
        sectionData={autoOverviewArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default AutoOverview;
